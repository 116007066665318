// Meta tags
export const addMetaTags = ({
  title = "",
  description = "",
  keywords = "",
  metaIamge = "",
  metaUrl = "",
}) => {
  // meta title
  document.title = title;

  // meta description
  document
    ?.querySelector("meta[name='description']")
    ?.setAttribute("content", description);

  // meta keyword
  document
    .querySelector("meta[name='keywords']")
    .setAttribute("content", keywords);

  // meta image
  if (metaIamge != "") {
    document
      .querySelector("meta[name='image']")
      .setAttribute("content", metaIamge);
  }

  // meta url
  if (metaUrl) {
    document.querySelector("meta[name='url']").setAttribute("content", metaUrl);
  }
};

// OG Tags
export const openGraphTags = ({
  ogTitle = "",
  ogDescription = "",
  ogImage = "",
  ogUrl = "",
  ogType = "",
  ogKeywords = "",
}) => {
  // OG title
  document
    ?.querySelector("meta[property='og:title']")
    ?.setAttribute("content", ogTitle);

  // OG description
  document
    ?.querySelector("meta[property='og:description']")
    ?.setAttribute("content", ogDescription);

  // OG image
  if (ogImage !== "") {
    document
      ?.querySelector("meta[property='og:image']")
      ?.setAttribute("content", ogImage);
  }

  // OG url
  document
    ?.querySelector("meta[property='og:url']")
    ?.setAttribute("content", ogUrl);

  // OG type
  document
    ?.querySelector("meta[property='og:type']")
    ?.setAttribute("content", ogType);

  // OG keywords
  document
    ?.querySelector("meta[property='og:keywords']")
    ?.setAttribute("content", ogKeywords);
};

// Twitter Card
export const twitterCardTags = ({
  twitterCard = "",
  twitterTitle = "",
  twitterDescription = "",
  twitterImage = "",
  twitterSite = "",
  twitterKeywords = "",
}) => {
  // Twitter card
  document
    ?.querySelector("meta[name='twitter:card']")
    ?.setAttribute("content", twitterCard);

  // Twitter title
  document
    ?.querySelector("meta[name='twitter:title']")
    ?.setAttribute("content", twitterTitle);

  // Twitter description
  document
    ?.querySelector("meta[name='twitter:description']")
    ?.setAttribute("content", twitterDescription);

  // Twitter image
  document
    ?.querySelector("meta[name='twitter:image']")
    ?.setAttribute("content", twitterImage);

  // Twitter site
  document
    ?.querySelector("meta[name='twitter:site']")
    ?.setAttribute("content", twitterSite);

  // Twitter keywords
  document
    ?.querySelector("meta[name='twitter:keywords']")
    ?.setAttribute("content", twitterKeywords);
};
