import React, { useEffect } from "react";

const WhatsAppWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js";
    script.id = "aisensy-wa-widget";
    script.setAttribute("widget-id", "taotSa");

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default WhatsAppWidget;
