import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gatewayFormValidator } from "../../../../utils/gatewayFormValidator";
import { Form, Button } from "react-bootstrap";
// import QRCodeCard from "../../../../utils/QrCodeCard";
import QRCodeCard from "../../../../utils/QrCodeCard";
import {
  updateDepositAmount,
  createDeposit,
} from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getActiveBankDetails } from "../../../../redux/app/actions";
// import CopyToClipboard from "../../../components/copyToClipBoard";
import CopyToClipboard from "react-copy-to-clipboard";
import BackIcon from "../../../../assets/svg/BackIcon";
import ArrowNext from "../../../../assets/images/arrow-next.png";
import Attention from "../../../../assets/images/attention.png";

import { AiFillCopy } from "react-icons/ai";
import LeftBarSports from "../../../components/LeftBarSports";
import TawkChat from "../../../components/TawkChat";
import WhatsAppWidget from "../../../components/WhatsAppWidget";
import numberWithCommas from "../../../../utils/numbersWithComma";

import CrossBtn from "../../../../assets/images/icons/close_btn-icon.png";

const CUSTOM_DEPOSIT_RESPONSE_TYPE = {
  QRCODE: "QRCODE",
  IMAGE: "IMAGE",
};

const DepositGateway = () => {
  const { selectedGateway, gatewaysList } = useSelector((state) => state.user);
  const { bankDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { gateway } = useParams();
  const isBank = gateway === "bank_transfer";
  const showQrImg = selectedGateway?.qrCode !== null;
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);
  const inputRef = useRef(null);
  const [dAmount, setDAmount] = useState(null);
  const [customdepositResponseType, setCustomDepositResponseType] =
    useState(null);
  const [customActionDepositResponse, setCustomActionDepositResponse] =
    useState(null);

  const amountValues = [1000, 5000, 10000, 50000, 100000, 500000];

  useEffect(() => {
    dispatch(getActiveBankDetails());
  }, []);

  const validateData = (dataToValidate) => {
    let fields = selectedGateway.fields;
    let validations = gatewayFormValidator(fields, dataToValidate);
    setErrors(validations);
    return validations;
  };

  const validateBankFormData = (data) => {
    const { amount, utrNo, txnImage } = data;
    let errorVar = {};
    if (!amount) {
      errorVar.amount = "Amount is required";
    }
    if (!utrNo) {
      errorVar.utrNo = "UTR is required";
    }
    if (!txnImage) {
      errorVar.txnImage = "Txn image is required";
    }
    setErrors(errorVar);
    return errorVar;
  };

  const handleChange = (e, type = "non-file") => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "file") {
      value = e.target.files?.[0];
    }
    let data = { ...formData, [name]: value };
    if (name === "amount") {
      setDAmount(value);
    }
    if (isBank) {
      validateBankFormData(data);
    } else {
      validateData(data);
    }

    setFormData(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isBank) {
      if (Object.values(validateData({ ...formData })).length == 0) {
        // console.log("formData", formData);
        if (
          selectedGateway.fields.some(
            (f) => f?.accessor === "gateway" && f?.required === true
          )
        ) {
          formData["gateway"] = selectedGateway?.code;
        }
        if (selectedGateway?.slug && selectedGateway?.slug !== null) {
          formData.slug = selectedGateway?.slug;
        }
        const response = await updateDepositAmount({
          data: formData,
          url: selectedGateway?.url,
          isMultiPart: selectedGateway?.fields.some((f) => f?.type === "file"),
        });
        handleSubmitResponse(response);
        // console.log("response", response);
      }
    } else {
      if (Object.values(validateBankFormData(formData)).length === 0) {
        createDeposit(formData)
          .then((res) => {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, res.data.data.message);
          })
          .catch((err) => {
            Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
          });
      }
    }
  };

  // Use useEffect to handle the logic of matching manual input with predefined amounts
  useEffect(() => {
    const matchedIndex = amountValues.indexOf(Number(dAmount));
    if (matchedIndex !== -1) {
      setActiveIndex(matchedIndex);
    } else if (dAmount !== null && dAmount !== "") {
      setActiveIndex(amountValues.length); // Special index for the input field
    } else {
      setActiveIndex(null);
    }
  }, [dAmount]);

  // Handle click outside to maintain active class on input click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        const matchedIndex = amountValues.indexOf(Number(dAmount));
        if (matchedIndex !== -1) {
          setActiveIndex(matchedIndex); // Match predefined value and set active index
        } else if (dAmount === "") {
          setActiveIndex(null); // Remove active class if input is empty
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dAmount]);

  //TODO  Add it for url
  const handleSubmitResponse = (value) => {
    if (value) {
      if (selectedGateway.type === "manual") {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, value.data.data.message);
      } else if (value?.data?.data?.data && "payUrl" in value?.data.data.data) {
        // Redirect url
        window.location.href = value?.data?.data?.data?.payUrl;
      } else if (
        value?.data?.data?.data &&
        "payImageUrl" in value?.data.data.data
      ) {
        // Show image
        setCustomActionDepositResponse(value?.data?.data?.data?.payImageUrl);
        setCustomDepositResponseType(CUSTOM_DEPOSIT_RESPONSE_TYPE.IMAGE);
      } else if (
        value?.data?.data?.data &&
        "qrContent" in value?.data.data.data
      ) {
        // Show qr code
        setCustomActionDepositResponse(value?.data?.data?.data?.qrContent);
        setCustomDepositResponseType(CUSTOM_DEPOSIT_RESPONSE_TYPE.QRCODE);
      } else if (value?.data?.data?.data && "url" in value?.data.data.data) {
        // Redirect url
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "alphapo") {
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "coinbase") {
        window.location.href = value?.data?.data?.data?.url;
      }
    }
  };

  const renderFields = (field) => {
    switch (field.type) {
      case "file":
        return (
          <>
            <Form.Group
              className="mb-3 position-relative"
              controlId="formBasicUserName"
            >
              <Form.Control
                type={field.type}
                placeholder={field.label}
                name={field.accessor}
                onChange={(e) => handleChange(e, "file")}
                style={{ display: !field.display ? "none" : "" }}
                isInvalid={!!errors[field.accessor]}
              />
              <span class="focus-border">
                <i></i>
              </span>
              <Form.Control.Feedback type="invalid">
                {errors[field.accessor]}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        );
      default:
        return (
          <>
            {selectedGateway.type === "auto" && (
              <div className="mb-4">
                <ul className="select-amount">
                  {amountValues.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setDAmount(item);
                        setFormData({ ...formData, amount: item });
                        setActiveIndex(index);
                      }}
                      className={activeIndex === index ? "active" : ""}
                    >
                      <small>INR</small> {numberWithCommas(item)}
                    </li>
                  ))}
                  {selectedGateway?.type === "auto" && (
                    <li
                      className={`fill-amount ${
                        activeIndex === amountValues.length ? "active" : ""
                      }`}
                    >
                      <Form.Group
                        className="position-relative"
                        controlId="formBasicUserName"
                        ref={inputRef}
                      >
                        <Form.Control
                          type={field.type}
                          value={dAmount || ""}
                          placeholder={field.label}
                          name={field.accessor}
                          onChange={handleChange}
                          onClick={() => setActiveIndex(amountValues.length)}
                          style={{ display: !field.display ? "none" : "" }}
                          isInvalid={!!errors[field.accessor]}
                        />
                        <span className="inr">INR</span>
                        <Form.Control.Feedback type="invalid">
                          {errors[field.accessor]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </li>
                  )}
                </ul>
              </div>
            )}
            {selectedGateway?.type === "manual" && (
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicUserName "
              >
                <Form.Control
                  type={field.type}
                  // value={dAmount || ""}
                  placeholder={field.label}
                  name={field.accessor}
                  onChange={handleChange}
                  style={{ display: !field.display ? "none" : "" }}
                  isInvalid={!!errors[field.accessor]}
                />
                <span class="focus-border">
                  <i></i>
                </span>
                <Form.Control.Feedback type="invalid">
                  {errors[field.accessor]}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </>
        );
    }
  };

  const renderBankTransferForm = () => {
    return (
      <Form className="gatewayForm">
        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"number"}
            placeholder={"Enter amount"}
            name={"amount"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["amount"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["amount"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"text"}
            placeholder={"Enter UTR no"}
            name={"utrNo"}
            onChange={(e) => handleChange(e)}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["utrNo"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["utrNo"]}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3 position-relative"
          controlId="formBasicUserName"
        >
          <Form.Control
            type={"file"}
            placeholder={"Select file"}
            name={"txnImage"}
            onChange={(e) => handleChange(e, "file")}
            // style={{ display: !field.display ? "none" : "" }}
            isInvalid={!!errors["txnImage"]}
          />
          <span class="focus-border">
            <i></i>
          </span>
          <Form.Control.Feedback type="invalid">
            {errors["txnImage"]}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          className="px-5 mb-4"
          variant="primary"
          onClick={handleSubmit}
          style={{ fontSize: "15px", borderRadius: "3px" }}
        >
          Submit
        </Button>
      </Form>
    );
  };

  const renderCustomActionResponse = () => {
    if (customdepositResponseType === CUSTOM_DEPOSIT_RESPONSE_TYPE.IMAGE) {
      return (
        <div>
          <div className="ml-4 ">
            <div className="bg-zinc-50 rounded-lg p-3 qrSize">
              <img src={customActionDepositResponse} />
            </div>
          </div>
        </div>
      );
    } else if (
      customdepositResponseType === CUSTOM_DEPOSIT_RESPONSE_TYPE.QRCODE
    ) {
      return (
        <div>
          <QRCodeCard text={customActionDepositResponse} />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <HeaderAfterLogin />

      {customActionDepositResponse && customdepositResponseType ? (
        <>
          <div className="HpaySection">
            <div
              className="HayQR"
              onClick={() => {
                setCustomActionDepositResponse(null);
                setCustomDepositResponseType(null);
              }}
            >
              <img src={CrossBtn} alt="CrossBtn" />
            </div>
            {renderCustomActionResponse()}
          </div>
        </>
      ) : (
        <main className="main">
          <div className="d-xl-none mt--14">
            <LeftBarSports />
          </div>

          {!isBank ? (
            <Container>
              <div className="headingSec">
                <h4>{selectedGateway?.name}</h4>
                <div className="back-link" onClick={() => navigate(-1)}>
                  <BackIcon /> Back
                </div>
              </div>
              {selectedGateway?.type === "manual" && (
                <>
                  <div className="valuesDeposit">
                    {/* <h3>Wallet : {selectedGateway?.value}</h3> */}
                    <h3>UPI : {selectedGateway?.displayValue}</h3>

                    <div className="copyValue">
                      <CopyToClipboard
                        text={selectedGateway?.displayValue}
                        onCopy={() => {
                          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                        }}
                      >
                        <AiFillCopy />
                      </CopyToClipboard>
                      {/* <CopyToClipboard
                      text={selectedGateway?.value?.slice(index1, index2)}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    /> */}
                      <div>Click to copy code</div>
                    </div>
                  </div>
                  {(selectedGateway?.showQr === true && (
                    <div className="d-flex">
                      {showQrImg ? (
                        <div className=" mx-auto  w-full max-w-[12rem]  relative">
                          <div className="ml-4 ">
                            <div className="bg-zinc-50 rounded-lg p-3 qrSize">
                              <img src={selectedGateway?.qrCode} />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <QRCodeCard text={selectedGateway?.value} />
                      )}
                    </div>
                  )) ||
                    null}
                </>
              )}
              <Form className="gatewayForm">
                {selectedGateway?.fields?.map((f, i) => {
                  return renderFields(f);
                })}
                {selectedGateway.type === "auto" && (
                  <p className="min-max">
                    <b>Min</b> <small>INR</small>1,000 - <b>Max</b>{" "}
                    <small>INR</small>1,000,000
                  </p>
                )}
                <Button
                  className={
                    selectedGateway.type === "auto" ? "next-btn" : "px-4 mb-4"
                  }
                  onClick={handleSubmit}
                  style={{ fontSize: "15px", borderRadius: "3px" }}
                >
                  {selectedGateway.type !== "auto" ? "Submit" : "Pay"}
                  {selectedGateway.type === "auto" && (
                    <img src={ArrowNext} alt="arrow next" />
                  )}
                </Button>
                {selectedGateway.type === "auto" && (
                  <div className="info-text">
                    <ul className="text">
                      <h5>
                        <img src={Attention} alt="attention" />
                        <span>Pay attention to</span>
                      </h5>
                      <li>
                        After clicking the <b>Pay</b>{" "}
                        <img src={ArrowNext} alt="attention" /> button you will
                        be redirected to the <b>payment page</b> where you will
                        be able to make payment in the desired currency of your
                        choice using <b>QR </b>
                        or <b>Wallet Address. E.g.</b> BTC, USDTT, ETH, LTC
                      </li>
                      <li>
                        Sending any currency other than the selected one will
                        result in loss of funds.
                      </li>
                      <li>
                        If the wallet you are using to send funds
                        <b> charges a fee</b> please send enough to cover the
                        requested deposit amount. Otherwise it will reduces the
                        total amount that is sent and you will get credit less
                        in your account.
                        <br />
                        <b>Example:</b> Deposit Amount + Fee = Amount You need
                        to send
                      </li>
                      <li>
                        We recommend you to always send a little more than the
                        requested amount <b>don't Worry</b> you will get credit
                        the whole amount in your account that you sent. This is
                        only to make a safe transaction environment
                      </li>
                      <li>
                        After sending the funds to the given address on the
                        payment page wait at least <b>5-10 mints</b> to get the
                        funds credit to your account. You can click
                        <b> Done</b> Button if you don’t want to wait.
                      </li>
                      <li>
                        If the timer expires on the payment page and you
                        redirected back to the website, <b>don’t worry</b> you
                        will get credit automatically if you already transferred
                        the funds.
                      </li>
                      {/* <li>
                        You can click the <b>Done</b> button available below
                        on the payment page to come to the website again after
                        sending your funds, you will see funds in your account
                        with-in sometime after processing.
                      </li> */}
                    </ul>
                  </div>
                )}
                {selectedGateway.type === "auto" && (
                  <div className="info-text">
                    <p className="note">
                      <span>Note:</span> In case you face any issue with payment
                      method or amount is not credit to your account contact us
                      immediately through the <b>live chat</b> or{" "}
                      <b>whatsapp</b>
                    </p>

                    <ul className="contact">
                      <li>
                        <a
                          className="wtsp"
                          href={
                            appDetails?.WHATS_APP_URL != ""
                              ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                              : "javascript:void(0)"
                          }
                          target={
                            appDetails?.WHATS_APP_URL != "" ? "_blank" : ""
                          }
                        >
                          Whatsapp
                        </a>
                      </li>
                      <li>
                        <a className="cntct" href="/contactus">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </Form>
            </Container>
          ) : (
            <Container>
              <div className="headingSec">
                {"Bank Transfer"}
                <div className="back-link" onClick={() => navigate(-1)}>
                  <BackIcon /> Back
                </div>
              </div>
              {bankDetails && (
                <div
                  className="mb-4"
                  style={{
                    background: "#111",
                    padding: "15px",
                    borderRadius: "5px",
                  }}
                >
                  <p>
                    Account name : {bankDetails?.acc_name}{" "}
                    <CopyToClipboard
                      text={bankDetails?.acc_name}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.acc_name}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      console.log("Abc");
                    }}
                  /> */}
                  </p>
                  <p>
                    Account number : {bankDetails?.acc_no}{" "}
                    <CopyToClipboard
                      text={bankDetails?.acc_no}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.acc_no}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                  </p>
                  <p>
                    IFSC code : {bankDetails?.ifsc_code}{" "}
                    <CopyToClipboard
                      text={bankDetails?.ifsc_code}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.ifsc_code}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                  </p>
                  <p className="mb-0">
                    Bank name : {bankDetails?.bank_name}{" "}
                    <CopyToClipboard
                      text={bankDetails?.bank_name}
                      onCopy={() => {
                        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                      }}
                    >
                      <AiFillCopy />
                    </CopyToClipboard>
                    {/* <CopyToClipboard
                    text={bankDetails?.bank_name}
                    onCopy={() => {
                      Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Text Copied");
                    }}
                  /> */}
                  </p>
                </div>
              )}
              {bankDetails && renderBankTransferForm()}
            </Container>
          )}
        </main>
      )}

      <TawkChat />
      <WhatsAppWidget />
      <Footer />
    </>
  );
};
export default DepositGateway;
